.chat-body-container {
  flex: 1;
  background: #f5f6f8;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.outro-digitando {
}