.preatendimento-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 500px;
    max-width: 90vw;
  }
}
