.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .image {
    display: flex;
    flex-direction: column;

    a {
      text-align: right;
      margin-bottom: 10px;
      color: #ffffff;
      cursor: pointer;
      font-size: 30px;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      max-width: 90vw;
      max-height: 90vw;
    }
  }
}
