.etc-loading::after {
    content: ".";
    font-size: 48px;
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: .10em;
    line-height: 0em;
    animation:
        etc-loading 1.5s steps(30, end) infinite
  }
  
  @keyframes etc-loading {
    0% { content: "." }
    33.33% { content: ".." }
    66.66% { content: "..." }
    100% { content: ".." }
  }