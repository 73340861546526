.input-container {
  display: flex;
  flex-direction: column;

  .label {
      margin: 10px 0;
  }

  input, textarea {
    padding: 15px 10px;
    font-size: 16px;
    flex: 1;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
  }

  .error {
    p {
      color: red;
      font-size: 14px;
    }
  }
}
