.chat-balao {
  margin: 10px 30px;
  position: relative;
  box-shadow: 2px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 2px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 2px 6px 5px -2px rgba(0, 0, 0, 0.39);
  font-size: 14px;
  color: #545a60;

  &.envio {
    background: #d5eda1;
    align-self: flex-end;
    border-radius: 5px 0 5px 5px;
    padding: 0px 30px 0 20px;
  }

  &:not(.envio) {
    background: #cee5fb;
    align-self: flex-start;
    border-radius: 0 5px 5px 5px;
    padding: 0px 20px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
  }

  &.envio::after {
    right: -15px;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #d5eda1;
  }

  &:not(.envio)::after {
    left: -15px;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #cee5fb;
  }

  .datahora {
    display: none;
    font-size: 12px;
    position: absolute;
    bottom: -40px;
    width: 100vw;
  }

  &.envio {
    .datahora {
      right: 0px;
      text-align: right;
    }
  }

  &:not(.envio) {
    .datahora {
      left: 0px;
      text-align: left;
    }
  }

  .mensagem {
    word-break: break-all;
  }

  .menu {
    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 5px 0;
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #ccc;
        cursor: pointer;

        &:hover {
          background: #ccc;
          font-weight: bold;
        }
      }
    }
  }

  .imagens {
    display: flex;
    flex-direction: row;
  }

  .imagem {
    max-width: 150px;
    max-height: 150px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-left: 20px;
    }

    img {
      max-width: 150px;
      max-height: 150px;
    }

    .imagem-botoes {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        margin: 5px;
        font-size: 24px;
        background: rgba(0, 0, 0, 0.8);
        border: 0;
        border-radius: 10px;
        color: #ffffff;
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &:hover {
      .imagem-botoes {
        display: flex;
      }
    }
  }

  &:hover {
    margin-bottom: 30px;

    .datahora {
      display: block;
    }
  }

  .status {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .arquivos {
    bottom: 70px;
    width: calc(100% - 40px);
    padding: 20px;
    border-top: 2px solid #eeeeee;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .arquivo {
      width: 300px;
      height: 60px;
      border-radius: 5px;
      border: 1px solid #eee;
      background: #eeeeee;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      position: relative;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 10px;
      }

      .icone {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #eee;
        background-color: #eee;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        i {
          font-size: 32px;
          color: #aaaaaa;
        }
      }

      .info {
        padding-left: 10px;
        align-self: flex-start;

        p {
          margin: 0;
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .size {
          font-size: 12px;
          font-weight: 300;
        }
      }

      &:hover {
        background: #eeeeee;
      }
    }
  }
}

.mensagem-notificacao {
  font-size: 14px;
  color: #545a60;
  margin: 10px 0 0 30px;
  text-align: center;

  .datahora {
    font-size: 12px;
    display: none;
  }

  &:hover {
    .datahora {
      display: block;
    }
  }
}
