.chat-header-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px 5px 0 0;

  .imagem-logo {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    background-image: url(https://www.radius.net.br/wp-content/uploads/2012/10/logo_radius.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    border: 1px solid #dddddd;
    margin-right: 10px;
  }

  p {
    margin: 0;

    &.titulo {
      font-size: 16px;
      font-weight: 500;
    }

    &.subtitulo {
      font-size: 14px;
    }
  }
}
