.editor-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: -1px -8px 21px -11px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: -1px -8px 21px -11px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: -1px -8px 21px -11px rgba(0, 0, 0, 0.49);

  form {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 10px;
      background: transparent;
      border: 0;
      color: #8c98bb;
      cursor: pointer;

      i {
        font-size: 24px;

        &:hover:not([disabled]) {
          color: #000000;
        }
      }

      &[disabled] {
        i {
          opacity: 0.3;
        }
      }
    }

    input {
      flex: 1;
      height: 50px;
      padding: 5px 20px;
      border-radius: 5px;
      border: thin solid #f5f6f8;
      color: #545a60;
      font-size: 14px;

      &:focus {
        outline: none;
      }
    }
  }

  .atalhos-wrapper {
    position: absolute;
    bottom: 70px;
    width: 100vw;
    max-height: 250px;
    overflow: auto;
    background: #ffffff;
    border-top: 3px solid #ddd;
    border-bottom: 3px solid #ddd;
    cursor: pointer;

    .atalho {
      padding: 10px;

      &:not(:last-child) {
        border-bottom: thin solid #ddd;
      }

      p {
        margin: 0;

        &.atalho-nome {
          font-size: 16px;
          font-weight: 500;

          p {
            float: right;
            font-size: 12px;
            font-weight: 400;
          }
        }

        &.atalho-mensagem {
          font-size: 14px;
        }
      }
    }
  }

  .emoji-wrapper {
    position: relative;

    .emoji-picker {
      position: absolute;
      top: 0;
    }
  }

  .arquivos-wrapper {
    position: absolute;
    bottom: 70px;
    width: calc(100% - 40px);
    padding: 20px;
    background: #ffffff;
    border-top: 2px solid #eeeeee;
    display: flex;
    flex-direction: row;
    overflow: auto;

    .arquivo {
      min-width: 300px;
      height: 60px;
      border-radius: 5px;
      border: 1px solid #eee;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      position: relative;

      &:not(:first-child) {
        margin-left: 10px;
      }

      .icone {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #eee;
        background-color: #eee;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        i {
          font-size: 32px;
          color: #aaaaaa;
        }
      }

      .info {
        padding-left: 10px;
        align-self: flex-start;

        p {
          margin: 0;
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .size {
          font-size: 12px;
          font-weight: 300;
        }
      }

      .excluir {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 24px;
        cursor: pointer;
      }

      &:hover {
        background: #eeeeee;

        .excluir {
          display: block;
        }
      }
    }
  }
}
