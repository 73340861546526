.button-container {
  width: 100%;
  padding: 15px 10px;
  border: 0;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
}
